class SegmentProductProperties {
  constructor(cartItem) {
    this.cartItem = cartItem;
  }

  call() {
    const { name, product_type_name: category } = this.cartItem;
    const { id, brand, price, quantity } = this.cartItem.analytics;
    const overwriteProperties = {};

    if (this.cartItem.addableType === "Giftcard") {
      overwriteProperties.product_id = "giftcard";
      overwriteProperties.sku = "giftcard";
      overwriteProperties.name = "Gift card";
    }

    return {
      product_id: id,
      sku: id,
      category,
      name,
      brand,
      price,
      quantity,
      ...overwriteProperties
    };
  }
}

export default SegmentProductProperties;
