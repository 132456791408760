/* eslint no-shadow: ["error", { "allow": ["state"] }] */

const state = {
  checkoutPath: "",
  rootPath: "",
  thanksPath: "",
  cartPath: "",
  zendeskPath: "",
  resetPasswordPath: "",
  plannerMarketingPath: "",
  postPaymentPath: "",
  savedDesignsPath: ""
};

const getters = {};
const actions = {
  redirectToBasket({ state }) {
    window.location.href = state.cartPath;
  }
};
const mutations = {
  setPaths(state, paths) {
    Object.entries(paths).forEach(([key, path]) => {
      state[key] = path;
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
