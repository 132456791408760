/* eslint no-shadow: ["error", { "allow": ["state", "getters"] }] */

import giftnoteFabric from "@/app/javascript/utils/giftnoteFabric.json";
import cartApi from "../../api/cart";

const state = {
  gift: false,
  baseGiftnoteText: "",
  whitespaceRegex: /\n{3,}/g,
  canHaveGiftnote: false,
  giftnote: null,
  giftnoteSvg: "",
  giftnoteSheet: {
    fabric: JSON.stringify(giftnoteFabric),
    width: giftnoteFabric.width,
    height: giftnoteFabric.height
  },
  giftnoteErrors: []
};

const getters = {
  giftnoteAvailable(_state, _getters, _rootState, rootGetters) {
    return rootGetters["cart/physicalShipments"].some(
      shipment => shipment.giftnote_available
    );
  },
  trimmedGiftnoteText: state =>
    state.baseGiftnoteText.replace(state.whitespaceRegex, "\n\n").trim()
};

const actions = {
  captureGiftnoteSvg({ state, getters }) {
    import("@/app/javascript/utils/offscreenRenderer")
      .then(({ default: offscreenRenderer }) => {
        return offscreenRenderer.renderAndReturnSvg(
          state.giftnoteSheet,
          getters.trimmedGiftnoteText
        );
      })
      .then(svg => {
        state.giftnoteSvg = svg;
      })
      .catch(error => {
        console.error("Error capturing Giftnote SVG:", error);
      });
  },
  setSavedGiftnoteText({ commit }) {
    commit("setBaseGiftnoteText", getters.trimmedGiftnoteText);
  },
  async handleGiftnote({ getters, commit, dispatch }) {
    if (getters.trimmedGiftnoteText.length < 10) {
      commit("setGiftnoteErrors", [
        "Your gift note needs to be a minimum of 10 characters"
      ]);
    } else if (getters.trimmedGiftnoteText.length) {
      await dispatch("createOrUpdateGiftNote");
    }
  },
  async createOrUpdateGiftNote({ state, getters, commit, dispatch }) {
    const action = state.giftnote ? "updateGiftnote" : "createGiftnote";

    dispatch(action, {
      text: getters.trimmedGiftnoteText,
      svg: state.giftnoteSvg
    }).then(() => {
      if (state.giftnoteErrors.length === 0) {
        commit("setBaseGiftnoteText", getters.trimmedGiftnoteText);
      }
    });
  },
  async createGiftnote({ dispatch, commit }, text) {
    commit("setLoading", true, { root: true });
    commit("setGiftnoteErrors", []);
    const response = await cartApi.createGiftnote(text);
    if (response) {
      if (response.success) {
        dispatch("cart/commitCartData", response.data, { root: true });
      } else {
        commit("setGiftnoteErrors", response.data.text);
      }
      commit("setLoading", false, { root: true });
    } else {
      // Handle error
    }
  },
  async updateGiftnote({ dispatch, commit, state }, text) {
    commit("setLoading", true, { root: true });
    commit("setGiftnoteErrors", []);
    const response = await cartApi.updateGiftnote(state.giftnote.id, text);
    if (response) {
      if (response.success) {
        dispatch("cart/commitCartData", response.data, { root: true });
      } else {
        commit("setGiftnoteErrors", response.data.text);
      }
      commit("setLoading", false, { root: true });
    } else {
      // Handle error
    }
  },
  async deleteGiftnote({ dispatch, commit, state }) {
    if (!state.giftnote) return;
    commit("setLoading", true, { root: true });
    const response = await cartApi.deleteGiftnote(state.giftnote.id);
    if (response) {
      dispatch("cart/commitCartData", response.data, { root: true });
      commit("setLoading", false, { root: true });
    }
  }
};

const mutations = {
  setGift(state, gift) {
    state.gift = gift;
  },
  setGiftnote(state, details) {
    state.giftnote = details.giftnote;
    state.canHaveGiftnote = details.canHaveGiftnote;
  },
  setGiftnoteErrors(state, errors) {
    state.giftnoteErrors = errors;
  },
  setBaseGiftnoteText(state, text) {
    state.baseGiftnoteText = text;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
