/* eslint no-shadow: ["error", { "allow": ["state"] }] */

const state = {
  options: {},
  surveyOption: "",
  surveyDetails: "",
  surveyQuestion: ""
};

const mutations = {
  setOptions(state, options) {
    state.options = options;
  },
  setOption(state, option) {
    state.surveyOption = option;
  },
  setDetails(state, details) {
    state.surveyDetails = details;
  },
  setQuestion(state, question) {
    state.surveyQuestion = question;
  }
};

export default {
  namespaced: true,
  state,
  mutations
};
