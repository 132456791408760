import api from "./index";

export default {
  async show() {
    return api.get("/basket.json");
  },
  async showWithIntent() {
    return api.get("/basket.json?load_intent=true");
  },
  async showWithKlarnaIntent() {
    return api.get("/basket.json?load_intent=true&klarna=true");
  },
  async showMiniCart() {
    return api.get("/basket.json?mini_cart=true");
  },
  async setShippingAddressId(id) {
    try {
      const response = await api.post(
        `/basket/addresses/${id}/set_shipping_address_id.json`
      );
      return response;
    } catch (error) {
      return false;
    }
  },
  async setPhoneNumber(id, phoneNumber) {
    try {
      const response = await api.patch(
        `/basket/addresses/${id}/set_phone_number.json`,
        {
          address: {
            telephone_number: phoneNumber
          }
        }
      );
      return response;
    } catch (error) {
      return false;
    }
  },
  async createGiftnote({ text, svg }) {
    try {
      const { data } = await api.post("/basket/giftnotes", { text, svg });
      return {
        data,
        success: true
      };
    } catch (error) {
      return {
        data: error.response.data.errors,
        success: false
      };
    }
  },
  async updateGiftnote(id, { text, svg }) {
    try {
      const { data } = await api.put(`/basket/giftnotes/${id}`, { text, svg });
      return {
        data,
        success: true
      };
    } catch (error) {
      return {
        data: error.response.data.errors,
        success: false
      };
    }
  },
  async deleteGiftnote(id) {
    try {
      const { data } = await api.delete(`/basket/giftnotes/${id}`);
      return {
        data
      };
    } catch (error) {
      return false;
    }
  },
  async setUsingStoreCredit(usingCredit) {
    try {
      return await api.put("/basket/set_using_credit?load_intent=true", {
        using_credit: usingCredit
      });
    } catch (error) {
      return false;
    }
  }
};
