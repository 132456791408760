/* eslint no-shadow: ["error", { "allow": ["state", "getters"] }] */
import cartItems from "../../api/cartItems";
import cart from "../../api/cart";
import upsells from "../../api/upsells";
import SegmentProductProperties from "../../utils/segmentProductProperties";

const state = {
  id: null,
  items: [],
  promoStockItems: [],
  shipments: [],
  shippingAddressId: null,
  sectionsToInclude: [],
  guestCheckoutEmail: null,
  phoneNumber: null,
  phoneNumberErrors: [],
  hasWeddingDate: false,
  weddingDateEnabled: false,
  shippingAddressRequired: null,
  usingStoreCredit: false,
  allItemsInStock: true,
  hasGiftcard: false,
  isSample: false,
  availableCreditUpsells: [],
  creditPackages: [],
  shippingWarning: "",
  initalItemCount: 0,
  fetchedMiniCart: false,
  locale: "",
  newCustomer: false,
  responsiveMode: false,
  isNegotiated: false,
  autoOptIn: false,
  loggedIn: null,
  showQuickPay: false,
  exceedsSampleLimit: false,
  paymentRequestResetCount: 0,
  paymentRequestModalOpen: false,
  showAppleQuickPayVariant: false,
  currentUserEmail: "",
  paymentRequestFailure: false,
  deal: null,
  optedIn: null,
  loyaltyBreakdown: null
};

const getters = {
  physicalShipments(state) {
    return state.shipments
      .filter(shipment => !shipment.giftcard)
      .sort((a, b) => a.cart_items[0].id > b.cart_items[0].id);
  },
  showDealCart(state) {
    if (!state.deal) return false;

    if (state.deal.meetsThreshold || state.deal.complete) {
      return true;
    }

    return false;
  },
  showProductDealOnCart(state, getters) {
    return (
      getters.showDealCart &&
      state.deal.strategy === "product" &&
      !state.deal.complete
    );
  },
  showSubtotalDealOnCart(state, getters) {
    return getters.showDealCart && state.deal.strategy === "subtotal";
  },
  numberOfItems(state) {
    if (state.fetchedMiniCart) {
      return state.items.length;
    }
    return state.initalItemCount;
  },
  sendToThemItems(state) {
    return state.shipments.reduce((items, shipment) => {
      if (shipment.direct_send) {
        items.push(shipment.cart_items);
      }

      return items;
    }, []);
  }
};

const actions = {
  async removeItem({ dispatch, commit }, item) {
    commit("setLoading", true, { root: true });
    const response = await cartItems.remove(item);
    commit("setLoading", false, { root: true });
    if (response) {
      const product = new SegmentProductProperties(item).call();
      analytics.track("Removed Product", {
        cart_id: this.state.cart.id,
        ...product
      });
      dispatch("commitCartData", response.data);
      commit("incrementPaymentRequestRemountCount");
    } else {
      // Handle error
    }
  },
  async setEnvelopeQty({ dispatch, commit }, { cartItemId, qty }) {
    try {
      commit("setLoading", true, { root: true });
      const response = await cartItems.setEnvelopeQty(cartItemId, qty);
      if (response) {
        dispatch("commitCartData", response.data);
        commit("incrementPaymentRequestRemountCount");
        commit("setLoading", false, { root: true });
      }
    } catch (error) {
      console.log(error);
    }
  },
  async setQty({ dispatch, commit }, items) {
    commit("setLoading", true, { root: true });
    const { cartItem, lineItem } = items;
    const response = await cartItems.setQty(
      cartItem,
      lineItem.index,
      lineItem.qty
    );
    commit("setLoading", false, { root: true });
    if (response) {
      dispatch("commitCartData", response.data);
      commit("incrementPaymentRequestRemountCount");
    } else {
      // Handle error
    }
  },
  async setThickness({ dispatch, commit }, { cartItem, lineItem, thick }) {
    commit("setLoading", true, { root: true });
    const response = await cartItems.setThickness(
      cartItem,
      lineItem.index,
      thick
    );
    commit("setLoading", false, { root: true });
    if (response) {
      dispatch("commitCartData", response.data);
    } else {
      // Handle error
    }
  },
  async upgradeThickness({ dispatch }, item) {
    const lineItem = item.lineItems.filter(li => !li.hidden)[0];
    const data = { cartItem: item, lineItem, thick: true };
    dispatch("setThickness", data);
  },
  async setShippingAddressId({ dispatch, commit }, id) {
    commit("setLoading", true, { root: true });
    const response = await cart.setShippingAddressId(id);
    if (response) {
      dispatch("commitCartData", response.data);
      commit("setLoading", false, { root: true });
    } else {
      // Handle error
    }
  },
  async setShippingOptionId({ dispatch, commit }, data) {
    const { cartItemId, id } = data;
    commit("setLoading", true, { root: true });
    const response = await cartItems.setShippingOptionId(cartItemId, id);
    if (response) {
      dispatch("commitCartData", response.data);
      commit("setLoading", false, { root: true });
    } else {
      // Handle error
    }
  },
  async removeShipmentItems({ dispatch, commit }, cartItemId) {
    commit("setLoading", true, { root: true });
    const response = await cartItems.removeShipmentItems(cartItemId);
    if (response) {
      if (!response.data.cart_items.length) {
        dispatch("paths/redirectToBasket", null, { root: true });
      } else {
        dispatch("commitCartData", response.data);
        commit("setLoading", false, { root: true });
      }
    } else {
      // Handle error
    }
  },
  async removeUpsell(
    { dispatch, commit },
    { itemId, upsellId, removeFully = false }
  ) {
    commit("setLoading", true, { root: true });
    const response = await upsells.removeUpsellFromCart({
      itemId,
      upsellId,
      removeFully
    });
    if (response) {
      dispatch("commitCartData", response.data);
      commit("setLoading", false, { root: true });
    } else {
      // Handle error
    }
  },
  async updateUpsellQuantity(
    { dispatch, commit },
    { itemId, upsellId, quantity }
  ) {
    commit("setLoading", true, { root: true });
    const response = await upsells.updateUpsellsQuantity({
      itemId,
      upsellId,
      quantity
    });
    if (response) {
      dispatch("commitCartData", response.data);
      commit("setLoading", false, { root: true });
    } else {
      // Handle error
    }
  },
  async setUsingStoreCredit({ commit, dispatch }, flag) {
    commit("setLoading", true, { root: true });
    const response = await cart.setUsingStoreCredit(flag);
    if (response) {
      dispatch("commitCartData", response.data);
      commit("setLoading", false, { root: true });
    }
  },
  async addWeddingCreditUpsell({ commit, dispatch }, upsell) {
    commit("setLoading", true, { root: true });
    const response = await cartItems.addWeddingCreditUpsell(upsell);
    if (response) {
      dispatch("commitCartData", response.data);
      commit("setLoading", false, { root: true });
    }
  },
  commitCartData({ commit }, data) {
    commit("setItems", data.cart_items);
    commit("setPromoStockItems", data.promo_stock_items);
    commit("setShipments", data.shipments);
    commit("setShippingAddressId", data.shipping_address_id);
    commit(
      "giftnote/setGiftnote",
      {
        giftnote: data.giftnote,
        canHaveGiftnote: data.can_have_giftnote
      },
      { root: true }
    );
    commit("setUsingStoreCredit", data.using_credit);
    commit("setShowQuickPay", data.showQuickPay);
    commit("totals/setTotals", data.totals, { root: true });
    commit("setAllItemsInStock", data.allItemsInStock);
    commit("setHasGiftcard", data.hasGiftcard);
    commit("setAvailableCreditUpsells", data.available_credit_upsells);
    commit("setCurrentUserEmail", data.currentUserEmail);
    commit("setOptedIn", data.optedIn);
    commit("setLoyaltyBreakdown", data.loyaltyBreakdown);
    commit("setCreditPackages", data.credit_packages);
    commit("payment/setStripeV2Intent", data.stripeV2Intent, { root: true });
    commit("payment/setBraintreeToken", data.braintreeToken, { root: true });
    commit("setHasSendToThemItems", data.hasSendToThemItems);
    commit("setHasWeddingDate", data.hasWeddingDate);
    commit("setWeddingDateEnabled", data.weddingDateEnabled);
    commit("setNewCustomer", data.newCustomer);
    commit("setDeal", data.deal);
    commit("directSend/setContactAddresses", data.contactAddresses, {
      root: true
    });
    commit("setFetchedMiniCart", true); // We don't need to fetch minicart data after cart page has loaded
    commit("user/setCards", data.cards, { root: true });
    commit("user/setCheckoutDotComCards", data.checkoutDotComCards, {
      root: true
    });
    commit("user/setStripeV2Cards", data.stripeV2Cards, { root: true });
    commit("setExceedsSampleLimit", data.exceedsSampleLimit);
  },
  commitMiniCartData({ commit }, data) {
    commit("setItems", data.cart_items);
    commit("totals/setTotals", data.totals, { root: true });
    commit("setFetchedMiniCart", true);
    commit("setDeal", data.deal);
    commit("setLoyaltyBreakdown", data.loyaltyBreakdown);
  },
  async fetchMiniCart({ commit, dispatch }) {
    commit("setLoading", true, { root: true });
    const { data } = await cart.showMiniCart();
    dispatch("commitMiniCartData", data);
    commit("setLoading", false, { root: true });
  },
  async updateAddressPhoneNumber({ commit, state }) {
    const response = await cart.setPhoneNumber(
      state.shippingAddressId,
      state.phoneNumber
    );
    if (response) {
      commit("user/setShippingAddresses", response.data.shipping_addresses, {
        root: true
      });
    }
  }
};

const mutations = {
  setId(state, id) {
    state.id = id;
  },
  setItems(state, items) {
    state.items = items;
  },
  setPromoStockItems(state, items) {
    state.promoStockItems = items;
  },
  setShipments(state, shipments) {
    state.shipments = shipments;
  },
  setShippingAddressRequired(state, shippingAddressRequired) {
    state.shippingAddressRequired = shippingAddressRequired;
  },
  removeItem(state, item) {
    state.items.splice(state.items.indexOf(item), 1);
  },
  setShippingAddressId(state, id) {
    state.shippingAddressId = id;
  },
  setIsSample(state, isSample) {
    state.isSample = isSample;
  },
  setNewCustomer(state, newCustomer) {
    state.newCustomer = newCustomer;
  },
  setSectionsToInclude(state, config) {
    const sections = [];
    if (config.awaitingNegotiation) {
      sections.push("b2bEnquiry");
    } else {
      sections.push("payment");
    }
    if (config.voucherFormRequired) sections.push("voucherForm");
    if (config.optionsRequired) sections.unshift("shippingOptions");
    if (config.addressRequired) sections.unshift("shippingAddress");
    sections.unshift("userInfo");
    state.sectionsToInclude = sections;
  },
  setVoucherFormShowingAtCheckout(state) {
    const sections = [...state.sectionsToInclude];
    sections.splice(sections.length - 1, 0, "voucherForm");
    state.sectionsToInclude = sections;
  },
  setGuestCheckoutEmail(state, email) {
    state.guestCheckoutEmail = email;
  },
  setPhoneNumber(state, phoneNumber) {
    state.phoneNumber = phoneNumber;
  },
  setPhoneNumberErrors(state, errors) {
    state.phoneNumberErrors = errors;
  },
  setHasWeddingDate(state, hasWeddingDate) {
    state.hasWeddingDate = hasWeddingDate;
  },
  setWeddingDateEnabled(state, weddingDateEnabled) {
    state.weddingDateEnabled = weddingDateEnabled;
  },
  setUsingStoreCredit(state, flag) {
    state.usingStoreCredit = flag;
  },
  setAllItemsInStock(state, flag) {
    state.allItemsInStock = flag;
  },
  setHasGiftcard(state, flag) {
    state.hasGiftcard = flag;
  },
  setLocale(state, locale) {
    state.locale = locale;
  },
  setAvailableCreditUpsells(state, availableCreditUpsells) {
    state.availableCreditUpsells = availableCreditUpsells;
  },
  setCreditPackages(state, creditPackages) {
    state.creditPackages = creditPackages;
  },
  setHasSendToThemItems(state, hasSendToThemItems) {
    state.hasSendToThemItems = hasSendToThemItems;
  },
  setShippingWarning(state, shippingWarning) {
    state.shippingWarning = shippingWarning;
  },
  setInitialItemCount(state, count) {
    state.initalItemCount = count;
  },
  setLoggedIn(state, flag) {
    state.loggedIn = flag;
  },
  setWeddingCreditUpsellDescription(state, description) {
    state.weddingCreditUpsellDescription = description;
  },
  setFetchedMiniCart(state, fetched) {
    state.fetchedMiniCart = fetched;
  },
  setResponsiveMode(state, responsiveMode) {
    state.responsiveMode = responsiveMode;
  },
  setAutoOptIn(state, flag) {
    state.autoOptIn = flag;
  },
  setIsNegotiated(state, flag) {
    state.isNegotiated = flag;
  },
  setPaymentRequestFailure(state, flag) {
    state.paymentRequestFailure = flag;
  },
  setShowQuickPay(state, flag) {
    state.showQuickPay = flag;
  },
  setExceedsSampleLimit(state, exceedsSampleLimit) {
    state.exceedsSampleLimit = exceedsSampleLimit;
  },
  incrementPaymentRequestRemountCount(state) {
    state.paymentRequestResetCount += 1;
  },
  setPaymentRequestModalOpen(state, flag) {
    state.paymentRequestModalOpen = flag;
  },
  setApplePayVariant(state, flag) {
    state.showAppleQuickPayVariant = flag;
  },
  setCurrentUserEmail(state, currentUserEmail) {
    state.currentUserEmail = currentUserEmail;
  },
  setDeal(state, deal) {
    state.deal = deal;
  },
  setOptedIn(state, flag) {
    state.optedIn = flag;
  },
  setLoyaltyBreakdown(state, loyaltyBreakdown) {
    state.loyaltyBreakdown = loyaltyBreakdown;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
