import { createStore } from "vuex";

import cart from "./modules/cart";
import paths from "./modules/paths";
import payment from "./modules/payment";
import survey from "./modules/cart/survey";
import totals from "./modules/totals";
import user from "./modules/user";
import directSend from "./modules/directSend";
import flashMessages from "./modules/flashMessages";
import giftnote from "./modules/giftnote";

export default createStore({
  modules: {
    cart,
    paths,
    payment,
    survey,
    totals,
    user,
    directSend,
    flashMessages,
    giftnote
  },
  state: {
    loading: false,
    countries: [],
    activeCountry: "",
    formErrors: [],
    modalHtml: "",
    defaultCountryIso: "GB",
    plannerCheckout: false,
    guestCheckoutFormShowing: null,
    oAuthProviders: null,
    adminUser: false,
    previewItem: null,
    previewItemObjectIndex: null,
    responsiveMode: false,
    mobileOrderSummaryOpen: false
  },
  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },
    setCountries(state, countries) {
      state.countries = countries;
    },
    setDefaultCountryIso(state, iso) {
      state.defaultCountryIso = iso;
    },
    setFormErrors(state, formErrors) {
      state.formErrors = formErrors;
    },
    closeModal(state) {
      state.modalHtml = "";
    },
    setGuestCheckoutFormShowing(state, guestCheckoutFormShowing) {
      state.guestCheckoutFormShowing = guestCheckoutFormShowing;
    },
    setOAuthProviders(state, oAuthProviders) {
      state.oAuthProviders = oAuthProviders;
    },
    setAdminUser(state, adminUser) {
      state.adminUser = adminUser;
    },
    setPreviewItem(state, { cartItem, objectIndex }) {
      state.previewItem = cartItem;
      state.previewItemObjectIndex = objectIndex;
    },
    setResponsiveMode(state, flag) {
      state.responsiveMode = flag;
    },
    setMobileOrderSummaryOpen(state, flag) {
      state.mobileOrderSummaryOpen = flag;
    }
  }
});
